import { AxiosError, AxiosResponse } from "axios";
import { api } from "../helpers/api";
import { Brouillon } from "../models/Brouillon";

export const useDossierBrouillon = () => {
    const getDossierBrouillonByToken = (token: string) => {
        return new Promise<Brouillon>((resolve, reject) => {
            api.get('/tools/brouillon/' + token)
                .then((response: AxiosResponse<Brouillon>) => resolve(response?.data))
                .catch((error: AxiosError) => reject(error?.response?.data));
        });
    }

    const cancelDossierBrouillonByToken = (token: string) => {
        return new Promise<Brouillon>((resolve, reject) => {
            api.get('/tools/brouillon/cancel/' + token)
                .then((response: AxiosResponse<Brouillon>) => resolve(response?.data))
                .catch((error: AxiosError) => reject(error?.response?.data));
        });
    }
    
    const updateDossierBrouillonByToken = (token: string, dossierBrouillon: Brouillon) => {
        return new Promise<any>((resolve, reject) => {
            api.put('/tools/brouillon/' + token, dossierBrouillon)
                .then((response: AxiosResponse<any>) => resolve(response?.data))
                .catch((error: AxiosError) => reject(error?.response?.data));
        });
    }

    return {
        getDossierBrouillonByToken,
        cancelDossierBrouillonByToken,
        updateDossierBrouillonByToken
    }
}
