//Bootstrap
import { Card, InputGroup, Button, Row, Col } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

//Hooks
import { useDataGouv } from "../hooks/useDataGouv";

//React
import React from 'react';
import { useEffect, useState } from "react";

//Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useMapInfo } from "../hooks/useMapInfo";
import { set } from "react-hook-form";
import logo from '../assets/logo.png';

function CardAddress() {
    /**
     * Hooks
     */
    const { apiAddress } = useDataGouv();
    const { mapInfo, setStoreAddress } = useMapInfo();

    /**
     * States
     */
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<any>([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [defaultAddress, setDefaultAddress] = useState(null);

    /**
     * useEffect
     */
    useEffect(() => {
        if (mapInfo.address?.label && !mapInfo.address?.coordinates?.latitude) {
            setTimeout(() => {
                setDefaultAddress(mapInfo.address?.label);
            }, 1000);
            
            setIsLoading(true);
        
            apiAddress(mapInfo.address?.label, 1)
                .then((response: any) => {
                    var options = response.features.map((feature) => {
                        return {
                            id: feature.properties.id,
                            label: feature.properties.label,
                            postcode: feature.properties.postcode,
                            housenumber: feature.properties.housenumber,
                            street: feature.properties.street,
                            city: feature.properties.city,
                            citycode: feature.properties.citycode,
                            score: feature.properties.score,
                            coordinates: {
                                latitude: feature.geometry.coordinates[1],
                                longitude: feature.geometry.coordinates[0]
                            }
                        }
                    });

                    if(options.length === 1) setStoreAddress(options[0]);
                    
                    setIsLoading(false);
                })
                .catch((error) => {
                    
                });
        }
    }, [mapInfo.address?.label]);

    /**
     * Handlers
     */
    const handleSearch = (query) => {
        console.log('search', query);
        setIsLoading(true);
        
        apiAddress(query, 5)
            .then((response: any) => {
                var options = response.features.map((feature) => {
                    return {
                        id: feature.properties.id,
                        label: feature.properties.label,
                        postcode: feature.properties.postcode,
                        housenumber: feature.properties.housenumber,
                        street: feature.properties.street,
                        city: feature.properties.city,
                        citycode: feature.properties.citycode,
                        score: feature.properties.score,
                        coordinates: {
                            latitude: feature.geometry.coordinates[1],
                            longitude: feature.geometry.coordinates[0]
                        }
                    }
                });

                setOptions(options);
                
                setIsLoading(false);
            })
            .catch((error) => {
                
            });
    }

    const handleChange = (selected) => {
        setSelectedOption(selected[0]);
        handleClick(selected[0]);
    }

    const handleClick = (selectedValue = null) => {
        //On centre la carte sur l'adresse sélectionnée
        setStoreAddress(selectedValue??selectedOption);
    }

    /**
     * Functions
     */
    const filterBy = () => true;
    
    /**
     * Render
     */
    return (
        <>
            {defaultAddress !== null && 
                <Card className="card-address">
                    <Card.Body>
                        <Row>
                            <Col className="mb-2 p-0 text-center">
                                <img src={logo} alt="logo" height="50" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup className="card-address-inputgroup">
                                    <AsyncTypeahead
                                        filterBy={filterBy}
                                        id="search-address"
                                        isLoading={isLoading}
                                        labelKey="label"
                                        minLength={4}
                                        onSearch={handleSearch}
                                        onChange={handleChange}
                                        options={options}
                                        placeholder="Entrez une adresse"
                                        positionFixed={true}
                                        defaultInputValue={defaultAddress||''}
                                        renderMenuItemChildren={(option, props) => (
                                            <>
                                                <span>{(option as any).label}</span>
                                            </>
                                        )}
                                    />
                                    <Button variant="primary" id="btn-adress" onClick={handleClick} disabled={!selectedOption}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
        </>
    );
}

export default CardAddress;