//React
import React from 'react';
import { useEffect } from "react";

//Hooks
import { useMapInfo } from "../hooks/useMapInfo";
import { useMap } from "react-leaflet";
import { useMapUtil } from "../hooks/useMapUtil";

//Bootstrap
import { Card, Spinner } from "react-bootstrap";

function MapLoader() {
    const map = useMap();
    const { mapUtil } = useMapUtil();

    return (
        <div style={{ position: 'absolute', bottom: '0px', left: '10px', zIndex: 20000 }}>
            {mapUtil.loaders.map((loader: any, index: number) => (
                <Card className="mb-1" key={loader.id}>
                    <Card.Body className="d-flex p-1">
                        <Spinner className="align-self-center" style={{ marginLeft: '5px' }} animation="border" variant="primary" key={index} size="sm" />
                        <span className="align-self-center" style={{ marginLeft: '10px', fontWeight: 'bold'}}>{loader.text}</span>
                    </Card.Body>
                </Card>
            ))};
        </div>
    );
}

export default MapLoader;