import { AxiosError, AxiosResponse } from "axios";
import { api } from "../helpers/api";
import { Brouillon } from "../models/Brouillon";
import { Referentiel } from "../models/Referentiel";

export const useReferentiel = () => {
    const getReferentielValues = (referentielType: string) => {
        return new Promise<Referentiel[]>((resolve, reject) => {
            api.get('/tools/referentiel/' + referentielType)
                .then((response: AxiosResponse<Referentiel[]>) => resolve(response?.data))
                .catch((error: AxiosError) => reject(error?.response?.data));
        });
    }

    return {
        getReferentielValues
    }
}
