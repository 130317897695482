import React from 'react';
import { useParams } from "react-router-dom";
import { useDossierBrouillon } from "../../../hooks/useDossierBrouillon";
import { useEffect, useState } from "react";
import { useMapInfo } from "../../../hooks/useMapInfo";
import { useMapUtil } from "../../../hooks/useMapUtil";
import { Card, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Controller, useForm } from "react-hook-form";
import { map } from "leaflet";
import context from "react-bootstrap/esm/AccordionContext";
import { useReferentiel } from "../../../hooks/useReferentiel";
import { Referentiel } from "../../../models/Referentiel";
import Swal from "sweetalert2";
import { Brouillon } from "../../../models/Brouillon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import logo from '../../../assets/logo.png';
import { useUser } from '../../../hooks/useUser';

function ResponseStep4() {
    /**
     * Hooks
     */
    const routeParams = useParams();
    const { dbToken } = routeParams;
    const { mapInfo, setStoreBrouillon } = useMapInfo();
    const { createLoader, deleteLoader } = useMapUtil();
    const { getReferentielValues } = useReferentiel();
    const { createUserFromInvitation } = useUser();

    /**
     * States
     */
    const [ loading, setLoading ] = useState(false);

    /**
     * Form
     */
    const schema = z.object({
        password: z.string().min(8, { message: 'Le mot de passe doit contenir au moins 8 caractères' }),
        passwordConfirm: z.string().refine((data) => data == getValues().password, { message: 'Les mots de passe ne correspondent pas' })
    });
    const { control, register, watch, reset, formState, getValues, setValue } = useForm({
		resolver: zodResolver(schema),
        mode: 'all',
	});
	const { isValid, dirtyFields, errors } = formState;
	const form = watch();

    /**
     * useEffect
     */

    /**
     * Handlers
     */
    const handleCreateAccount = () => {
        setStoreBrouillon(null);
        setLoading(true);
        createUserFromInvitation(mapInfo.invitationToken, form.password)
            .then((user) => {
                Swal.fire({
                    title: 'Merci',
                    html: 'Votre compte a bien été créé.<br />Vous pouvez maintenant vous connecter sur <a href="https://pro.liaisons-habitat.fr">https://pro.liaisons-habitat.fr</a> et commencer à suivre vos biens.',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Erreur',
                    html: 'Une erreur est survenue lors de la création de votre compte.<br />Veuillez réessayer ultérieurement.',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
            });
    };

    return (
        <Modal show={mapInfo.brouillon?.etape == 4} size="lg" fullscreen={'lg-down'} onHide={() => {}}>
            <Modal.Body>
                <Row className="mt-3 mb-3">
                    <Col className="text-center">
                        <img src={logo} alt="logo" height="50" className="mb-3" /><br /><br />
                        <FontAwesomeIcon icon={faCheckCircle} size="5x" color="green" className="mb-2" />
                        <h1>Merci</h1>
                    </Col>
                </Row>
                <Row className="mb-2 mt-2">
                    <Col className="text-center">
                        Votre bien a été déposé sur notre portail, il est actuellement en cours de qualification par nos équipes avant sa mise en ligne.<br />
                        <br />
                        Afin de suivre l'avancement de la qualification de votre bien, nous vous invitons à créer un compte en choisissant votre mot de passe ci-dessous.<br />
                        <br />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="text-center">
                        <Form>
                            <Controller
                                render={({field}) => (
                                    <Form.Group className="mb-3" controlId="formPassword">
                                        <Form.Label>Choisissez votre mot de passe</Form.Label>
                                        <Form.Control type="password" {...field} required placeholder="" />
                                        {errors.password && <Form.Text className="text-danger">{errors.password.message as string}</Form.Text>}
                                    </Form.Group>
                                )}
                                name='password'
                                control={control}
                                key="password"
                            />
                            <Controller
                                render={({field}) => (
                                    <Form.Group className="mb-3" controlId="formPasswordConfirm">
                                        <Form.Label>Confirmez votre mot de passe</Form.Label>
                                        <Form.Control type="password" {...field} required placeholder="" />
                                        {errors.passwordConfirm && <Form.Text className="text-danger">{errors.passwordConfirm.message as string}</Form.Text>}
                                    </Form.Group>
                                )}
                                name='passwordConfirm'
                                control={control}
                                key="passwordConfirm"
                            />
                        </Form>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="text-center">
                        Pour plus d'informations, consultez la <strong>boîte à questions</strong> de notre portail en <a href="https://liaisons-habitat.fr/faq/">cliquant ici</a>.
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleCreateAccount} disabled={!isValid || loading}>
                    {loading ? (
                        <><Spinner animation="border" size="sm" /> Création en cours</>
                    ) : (
                        <>
                            Créer votre compte
                        </>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ResponseStep4;
