import { useDispatch, useSelector } from "react-redux";
import { addLoader, removeLoader, selectMapUtil } from "../store/mapUtilSlice";

export const useMapUtil = () => {
    const dispatch = useDispatch();
    const mapUtil = useSelector(selectMapUtil);

    const createLoader = (id: string, text: string) => {
        dispatch(addLoader({
            id: id,
            text: text
        }));
    }

    const deleteLoader = (id: string) => {
        dispatch(removeLoader(id));
    }

    return {
        mapUtil,
        createLoader,
        deleteLoader,
    }
}
