import { AxiosError, AxiosResponse } from "axios";
import { api } from "../helpers/api";
import { Brouillon } from "../models/Brouillon";
import { User } from "../models/User";

export const useUser = () => {
    const createUserFromInvitation = (token: string, password: string) => {
        return new Promise<User>((resolve, reject) => {
            api.post('/invite/' + token, {password})
                .then((response: AxiosResponse<User>) => resolve(response?.data))
                .catch((error: AxiosError) => reject(error?.response?.data));
        });
    }

    return {
        createUserFromInvitation
    }
}
