import { useDispatch, useSelector } from "react-redux";
import { selectMapInfo, setMapCoordinates, setMapAddress, addMapParcelle, setMapParcelles, setBrouillon, setInvitationToken } from "../store/mapInfoSlice";

export const useMapInfo = () => {
    const dispatch = useDispatch();
    const mapInfo = useSelector(selectMapInfo);

    const centerMap = (centerCoordinates: any, zoom = 18) => {
        dispatch(setMapCoordinates({...centerCoordinates, zoom: zoom}));
    }

    const setStoreAddress = (address: any) => {
        dispatch(setMapAddress(address));
        centerMap(address.coordinates);
    }

    const addStoreParcelle = (parcelle) => {
        dispatch(addMapParcelle(parcelle));
    }

    const removeStoreParcelle = (parcelleId) => {
        const newParcelles = mapInfo.parcelles.filter((parcelle) => parcelle.id !== parcelleId);
        dispatch(setMapParcelles(newParcelles));
    }

    const cleanStoreParcelle = () => {
        dispatch(setMapParcelles([]));
    }

    const setStoreBrouillon = (brouillon) => {
        dispatch(setBrouillon(brouillon));
    }

    const setStoreInvitationToken = (invitationToken) => {
        dispatch(setInvitationToken(invitationToken));
    }

    return {
        mapInfo,
        centerMap,
        setStoreAddress,
        addStoreParcelle,
        removeStoreParcelle,
        cleanStoreParcelle,
        setStoreBrouillon,
        setStoreInvitationToken
    }
}
